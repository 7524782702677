import React from "react";
import logo from "../images/rulogo1.png";
import { Link, useLocation } from "react-router-dom";
import { Button } from 'antd';
import {authStore} from "../data/mobx/authStore";

export function Navigation() {
    const location = useLocation();
    const hideLinks = location.pathname === '/login' || location.pathname === '/register';
    const handleLogout = () => {
        authStore.logout();
    };

    return (
        <nav className="flex justify-between items-center h-[50px] sm:h-[100px] px-5 sm:px-10 z-20 bg-custom-background">
            <a className="z-10 max-w-28 sm:max-w-xl transparent-bg" href={"/"}>
                <img src={logo} width={250} height={125} alt="" className="transparent-bg"/>
            </a>
            {!hideLinks && (
                <span className="z-10 flex space-x-5 transparent-bg items-center">
                    <Link to="/news"
                          className="font-light font-comfortaa text-xs sm:text-lg hover:text-raspberry z-50 transparent-bg flex items-center">
                        Новости
                    </Link>
                    <Link to="/choose"
                          className="font-light font-comfortaa text-xs sm:text-lg hover:text-raspberry z-50 transparent-bg flex items-center">
                        Курс
                    </Link>
                    <Link to="/about"
                          className="font-light font-comfortaa text-xs sm:text-lg hover:text-raspberry z-50 transparent-bg flex items-center">
                        О Проекте
                    </Link>
                    <Link to="/contacts"
                          className="font-light font-comfortaa text-xs sm:text-lg hover:text-raspberry z-50 transparent-bg flex items-center">
                        Контакты
                    </Link>
                    {authStore.isAuthenticated ? (<Link className="z-10 flex items-center" to="/account">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button mb-3 font-comfortaa text-sm items-center"
                            style={{background: '#C61437', width: '100px', height: '35px', marginBottom: '0px'}}
                            //onClick={handleLogout}
                        >
                            <div style={{backgroundColor: '#C61437'}}>Профиль</div>
                        </Button>
                    </Link>) : (<Link className="z-10 flex items-center" to="/login">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button mb-3 font-comfortaa text-sm items-center"
                            style={{background: '#C61437', width: '100px', height: '35px', marginBottom: '0px'}}
                        >
                            <div style={{backgroundColor: '#C61437'}}>Войти</div>
                        </Button>
                    </Link>)}

                </span>
            )}
        </nav>
    );
}
