import React, { useState, useEffect, useRef } from "react";
import { Carousel, CollapseProps, Collapse, Button } from "antd";
import { observer } from "mobx-react-lite";
import articlesStore, { Article } from "../data/mobx/articlesStore";

// Настройки карусели
const carouselSettings = {
    autoplay: false,
    dots: true,
    arrows: true,
    swipeToSlide: true,
};

const MAX_PREVIEW_LENGTH = 0; // Количество символов в превью

// Функция для сокращения текста
const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength) : text;
};

// Компонент для сворачивания текста внутри Collapse
const CollapsibleText = ({ text }: { text: string }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div>
            <div
                dangerouslySetInnerHTML={{
                    __html: expanded ? text.replace(/\n/g, "<br/>") : truncateText(text, MAX_PREVIEW_LENGTH),
                }}
            />
            <Button
                type="link"
                className="font-comfortaa"
                onClick={() => setExpanded(!expanded)}
                style={{
                    padding: 0,
                    marginTop: 5,
                    color: "#C61437",
                    fontWeight: "lighter",
                }}
            >
                {expanded ? "Скрыть текст урока" : "Показать текст урока"}
            </Button>
            <Button href={"/first"}
                type="link"
                className="font-comfortaa"
                style={{
                    padding: 0,
                    marginTop: 5,
                    marginLeft: 20,
                    color: "#C61437",
                    fontWeight: "lighter",
                }}
            >Перейти к тесту</Button>
        </div>
    );
};

export const MaterialsPage = observer(() => {
    const [collapseWidth, setCollapseWidth] = useState<number>(800);
    const collapseRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        articlesStore.fetchArticles(); // Загружаем статьи при монтировании

        // Функция обновления ширины Collapse
        const updateWidth = () => {
            if (collapseRef.current) {
                setCollapseWidth(collapseRef.current.offsetWidth);
            }
        };

        updateWidth();
        window.addEventListener("resize", updateWidth);

        return () => window.removeEventListener("resize", updateWidth);
    }, []);

    // Преобразуем статьи в формат Collapse
    const transformArticlesToCollapseItems = (articles: Article[]): CollapseProps["items"] => {
        return articles.map((article) => ({
            key: article.id,
            label: <div style={{ color: "#C61437" }}>{article.title}</div>,
            children: (
                <div>
                    {/* Карусель по центру */}
                    {article.images && article.images.length > 0 && (
                        <div ref={collapseRef} style={{ display: "flex", justifyContent: "center" }}>
                            <Carousel {...carouselSettings} style={{ width: 960, height: 540 }}>
                                {article.images.map((image, index) => (
                                    <div key={index} style={{ display: "flex", justifyContent: "center" }}>
                                        <img
                                            src={image}
                                            alt={`Image ${index}`}
                                            style={{
                                                width: "100%",
                                                maxWidth: "1000px",
                                                height: "510px",
                                                objectFit: "cover",
                                                borderRadius: "10px",
                                            }}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    )}
                    <CollapsibleText text={article.content} />
                </div>
            ),
        }));
    };

    return (
        <div className="bg-custom-background wrapper h-[calc(100vh-100px)]">
            <div className="flex justify-start ml-14 mr-14 mt-7 transparent-bg">
                <div className="textabout font-rounds-black text-greytext text-xl z-10">
                    Учебные материалы
                </div>
            </div>
            <div className="flex justify-start ml-14 mr-14 mt-7 mb-7">
                <div className="w-full z-10">
                    <Collapse accordion
                        items={transformArticlesToCollapseItems(articlesStore.articles)}
                        className="font-comfortaa"
                        style={{ fontSize: "14px" }}
                        defaultActiveKey={[]} // Все статьи свернуты
                    />
                </div>
            </div>
        </div>
    );
});