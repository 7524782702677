import React, {useState} from "react";
import { observer } from "mobx-react-lite";
import { authStore } from "../data/mobx/authStore";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {Button, Form, Input, Modal, notification} from 'antd';
import { useNavigate } from "react-router-dom";


export const LoginPage = observer(() => {
    const navigate = useNavigate();  // Хук для навигации

    const onFinish = async (values: any) => {
        authStore.setEmail(values.email);
        authStore.setPassword(values.password);
        await authStore.login();

        // Перенаправление после успешной авторизации
        if (authStore.isAuthenticated) {
            navigate('/');  // Перенаправление на главную страницу
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="bg-custom-background wrapper h-[calc(100vh-100px)]">
            <div className="flex justify-center items-center h-[80vh] transparent-bg">
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    style={{ width: 300, background: 'transparent' }}
                >
                    <div className="flex justify-center space-x-4 mb-5 transparent-bg">
                        <div className="text-2xl font-comfortaa font-extralight text-raspberry hover:text-raspberry transparent-bg">Вход</div>
                        <div className="text-2xl font-comfortaa font-extralight hover:no-hover-color transparent-bg">/</div>
                        <a href="/register" className="text-2xl font-comfortaa font-extralight hover:text-raspberry transparent-bg">Регистрация</a>
                    </div>

                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your E-mail!' }]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            type="email"
                            placeholder="E-Mail"
                            style={{ borderColor: '#C61437', background: 'transparent' }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Пароль"
                            style={{ borderColor: '#C61437', background: 'transparent' }}
                        />
                    </Form.Item>


                    {authStore.error && <div style={{ color: 'red', marginBottom: '10px', marginTop: '-15px' }}>{authStore.error}</div>}

                    <div className="transparent-bg">
                        <Form.Item className="flex flex-col items-center transparent-bg">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button mb-3 font-comfortaa text-sm transparent-bg"
                                style={{ background: '#C61437', width: '200px', height: '35px' }}
                                loading={authStore.isLoading}
                            >
                                <div style={{ backgroundColor: '#C61437' }}>Войти</div>
                            </Button>
                            <a className="hover:text-raspberry font-comfortaa text-xs flex justify-center transparent-bg"
                               //onClick={showModal}
                            >Забыли пароль?</a>
                            <Modal title="Восстановление пароля" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                <p>Some contents...</p>
                                <p>Some contents...</p>
                                <p>Some contents...</p>
                            </Modal>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    );
});
