import React, {useEffect, useState} from "react";
import { Button, Form, Input, Modal } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { authStore } from "../data/mobx/authStore";
import {changepwdStore} from "../data/mobx/changepwdStore";
import {Link} from "react-router-dom";
import userDataStore from "../data/mobx/userdataStore";

export function AccountPage() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm(); // Используем хук для управления формой

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        form.submit(); // Вызываем отправку формы
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields(); // Очищаем поля формы при закрытии модального окна
    };

    const onFinish = async (values: any) => {
        // Выполняем запрос через MobX-стор
        changepwdStore.setEmail(values.email);
        changepwdStore.setPassword(values.password);
        await changepwdStore.changePassword();
    };

    const customFooter = [
        <Button
            key="cancel"
            onClick={handleCancel}
            className="font-comfortaa text-sm transparent-bg"
            style={{ background: '#C61437', borderColor: '#C61437', color: '#FFFFFF' }}
        >
            Отмена
        </Button>,
        <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            className="font-comfortaa text-sm transparent-bg"
            style={{ background: '#C61437', borderColor: '#C61437', color: '#FFFFFF' }}
        >
            Подтвердить
        </Button>
    ];

    return (
        <div className="bg-custom-background wrapper h-[calc(100vh-100px)]">
            <div className="flex justify-start ml-14 mr-14 mt-7 transparent-bg">
                <div className="textabout font-rounds-black text-greytext text-xl z-10">
                    Мой Профиль
                </div>
            </div>

            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button ml-14 mr-14 mt-7 font-comfortaa text-sm transparent-bg"
                style={{ background: '#C61437', width: '250px', height: '35px' }}
                onClick={showModal}
            >
                <div style={{ backgroundColor: '#C61437' }}>Сменить пароль</div>
            </Button>
            <div>
                <Link to="/login">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button ml-14 mr-14 mt-5 font-comfortaa text-sm transparent-bg"
                        style={{ background: '#C61437', width: '250px', height: '35px' }}
                        onClick={() => authStore.logout()}
                    >
                        <div style={{ backgroundColor: '#C61437' }}>Выход</div>
                    </Button>
                </Link>
            </div>
            <Modal
                title="Сброс пароля"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={customFooter}
            >
                <Form
                    form={form} // Передаем управление формой
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish} // Указываем метод для отправки формы
                    style={{ width: 470, background: 'transparent' }}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your E-mail!' }]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            type="email"
                            placeholder="E-Mail"
                            style={{ borderColor: '#C61437', background: 'transparent' }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Пароль"
                            style={{ borderColor: '#C61437', background: 'transparent' }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}