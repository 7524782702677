import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import Frame11 from './Viruses/Frame1-1.png';
import Frame12 from './Viruses/Frame1-2.png';
import Frame13 from './Viruses/Frame1-3.png';
import Frame14 from './Viruses/Frame1-4.png';
import Frame21 from './Datamining/Frame21.png';
import Frame22 from './Datamining/Frame22.png';
import Frame23 from './Datamining/Frame23.png';
import Frame24 from './Datamining/Frame24.png';
import Frame25 from './Datamining/Frame25.png';
import Frame26 from './Datamining/Frame26.png';
import Frame31 from './Soceng/Frame31.png';
import Frame32 from './Soceng/Frame32.png';
import Frame33 from './Soceng/Frame33.png';
import Frame34 from './Soceng/Frame34.png';
import Frame35 from './Soceng/Frame35.png';
import Frame36 from './Soceng/Frame36.png';
import Frame37 from './Soceng/Frame37.png';
const API_URL = process.env.REACT_APP_API_URL;

export interface Article {
    id: string;
    title: string;
    content: string;
    // Добавляем опциональное поле для изображений
    images?: string[];
}

class ArticlesStore {
    articles: Article[] = [];

    constructor() {
        makeAutoObservable(this);
        this.fetchArticles();
    }

    async fetchArticles() {
        try {
            // Подгружаем статьи с бэка
            const response = await axios.get<Article[]>(
                `${API_URL}/api/articles?_=${new Date().getTime()}`
            );
            // Добавляем локальные изображения к каждой статье
            const articlesWithLocalImages = response.data.map((article) => {
                // В зависимости от ID статьи (или других критериев) добавляем изображения
                const localImages = this.getLocalImagesForArticle(article.id);
                return {
                    ...article,
                    images: this.getLocalImagesForArticle(article.id),
                };
            });
            this.articles = articlesWithLocalImages;
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    }

    // Определяем локальные картинки для разных статей
    getLocalImagesForArticle(articleId: string): string[] {
        // Пример логики — в реальном приложении можно использовать другое соответствие
        switch (articleId) {
            case '11':
                return [Frame11, Frame12, Frame13, Frame14];
            case '10':
                return [Frame21, Frame22, Frame23, Frame24, Frame25, Frame26];
            case '12':
                return [Frame31, Frame32, Frame33, Frame34, Frame35, Frame36, Frame37];
            default:
                return [];
        }
    }
}
const articlesStore = new ArticlesStore();
export default articlesStore;