import React from "react"
import {Link} from "react-router-dom";
import {Button} from "antd";
import mk_book from "../images/mk_books.png";

export function LearnAboutPage() {
    return (
        <div className="bg-custom-background wrapper h-[calc(100vh-100px)]">
            <div className=" transparent-bg flex justify-start ml-14 mr-14 mt-7">
                <div className="textabout font-rounds-black text-greytext text-3xl z-10 transparent-bg">
                    Образовательный модуль
                </div>
            </div>
            <div className="flex justify-start ml-14 mr-14 mt-5 z-10 transparent-bg">
                <div className="textabout font-comfortaa text-greytext text-m sm:text-xl z-10 w-1/2">
                    Образовательное приложение по системному изучению цифровых угроз и возможных
                    инструментов защиты состоит из 6-ти блоков, в каждом из которых необходимо изучить
                    теорию и закрепить тестированием. Ядро образовательного модуля составляет
                    книга И. Ашманова и Н. Касперской «Цифровая гигиена». Права на использование
                    согласованы с авторами и издателями книги. Также команда регулярно собирает информацию
                    по угрозам сети Интернет, что позволяет обновлять данные в образовательном приложении.
                </div>
            </div>
            <div className="flex justify-start ml-14 mr-14 mt-7 z-10 transparent-bg">
                <Link className="z-10 transparent-bg" to="/learn">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mb-3 font-comfortaa text-sm"
                        style={{background: '#C61437', width: '200px', height: '35px'}}
                    >
                        <div style={{backgroundColor: '#C61437'}}>Приступить</div>
                    </Button>
                </Link>
            </div>
            <div className="mainmonkey-about mb-5">
                <img className="transparent-bg" src={mk_book} width={600} alt={"/"}/>
            </div>
            <div className="h-[35vh] transparent-bg"></div>
        </div>
    )
}