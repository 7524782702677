import React from "react"

export function ContactsPage() {
    return (
        <div className="bg-custom-background wrapper h-[calc(100vh-100px)]">
            <div className="flex justify-start ml-14 mr-14 mt-7 transparent-bg">
                <div className="textabout font-rounds-black text-greytext text-2xl z-10">
                    Наши контакты
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-7 transparent-bg">
                <div className="textabout font-comfortaa text-greytext text-m sm:text-xl z-10">
                    Юридическая информация: ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИНФОГУРУ"
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-7 transparent-bg">
                <div className="textabout font-comfortaa text-greytext text-m sm:text-xl z-10">
                    ОГРН: 1235900019937
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-7 transparent-bg">
                <div className="textabout font-comfortaa text-greytext text-m sm:text-xl z-10">
                    Юр.адрес: Пермский край, м.о. Ординский, с Сосновка, ул Мира, двлд. 30
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-7 transparent-bg">
                <div className="textabout font-comfortaa text-greytext text-m sm:text-xl z-10">
                    Почта: braginastia@yandex.ru
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-7 transparent-bg">
                <div className="textabout font-comfortaa text-greytext text-m sm:text-xl z-10">
                    Подпишитесь: <a href="https://t.me/info_gu_ru" className="ml-2 text-raspberry transparent-bg"> https://t.me/info_gu_ru</a>
                </div>
            </div>
        </div>
    )
}