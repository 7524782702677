import { makeAutoObservable } from "mobx";
import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

class ChangepwdStore {
    email = "";
    newPassword = "";
    isLoading = false;
    error = "";

    constructor() {
        makeAutoObservable(this);
    }

    setEmail(email: string) {
        this.email = email;
    }

    setPassword(newPassword: string) {
        this.newPassword = newPassword;
    }

    async changePassword() {
        this.isLoading = true;
        this.error = "";

        try {
            const response = await axios.put(`${API_URL}/api/auths/change-password`, {
                newPassword: this.newPassword,
                email: this.email
            });

            console.log("Change successful:", response.data);
        } catch (error: any) {
            this.error = error.response?.data?.message || "Change failed";
        } finally {
            this.isLoading = false;
        }
    }
}

export const changepwdStore = new ChangepwdStore();
