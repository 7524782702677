import React from 'react';
import { Navigation } from "./components/NavigationTop";
import { Route, Routes, Navigate } from "react-router-dom";
import MainPage from "./pages/MainPage";
import { ChooseTest } from "./pages/ChooseTest";
import { TestPageOne } from "./pages/TestPageOne";
import { TestPageTwo } from "./pages/TestPageTwo";
import { TestPageThree } from "./pages/TestPageThree";
import { AboutPage } from "./pages/AboutPage";
import { FestivalPage } from "./pages/FestivalPage";
import { LoginPage } from "./pages/LoginPage";
import { RegisterPage } from "./pages/RegisterPage";
import { MaterialsPage } from "./pages/MaterialsPage";
import { authStore } from "./data/mobx/authStore";
import {ContactsPage} from "./pages/ContactsPage";
import NewsPage from "./pages/NewsPage";
import {LearnAboutPage} from "./pages/LearnAboutPage";
import {GameAboutPage} from "./pages/GameAboutPage";
import {AccountPage} from "./pages/AccountPage";

type PrivateRouteProps = {
    element: React.ReactNode;
};

// Компонент для защиты маршрутов
const PrivateRoute = ({ element }: PrivateRouteProps) => {
    return authStore.isAuthenticated ? (
        <>{element}</>
    ) : (
        <Navigate to="/login" replace />
    );
};

function App() {
    return (
        <>
            <div className="wrapper">
                <Navigation />
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="choose" element={<ChooseTest />} />
                    <Route path="first" element={<PrivateRoute element={<TestPageOne />} />} />
                    <Route path="second" element={<PrivateRoute element={<TestPageTwo />} />} />
                    <Route path="third" element={<PrivateRoute element={<TestPageThree />} />} />
                    <Route path="about" element={<AboutPage />} />
                    <Route path="contacts" element={<ContactsPage />} />
                    <Route path="festival" element={<FestivalPage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="register" element={<RegisterPage />} />
                    <Route path="news" element={<NewsPage />} />
                    <Route path="learn" element={<PrivateRoute element={<MaterialsPage />} />} key="learn" />
                    <Route path="aboutlearn" element={<PrivateRoute element={<LearnAboutPage />} />} key="aboutlearn" />
                    <Route path="aboutgame" element={<PrivateRoute element={<GameAboutPage />} />} key="aboutgame" />
                    <Route path="account" element={<PrivateRoute element={<AccountPage />} />} key="account" />
                </Routes>
            </div>
        </>
    );
}

export default App;
