import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Card, Spin, Alert } from "antd";
import newsStore from "../data/mobx/newsStore";
import 'antd/dist/reset.css'; // Добавьте Ant Design CSS

const { Meta } = Card;

const NewsPage: React.FC = observer(() => {
    useEffect(() => {
        newsStore.fetchNews();
    }, []);

    if (newsStore.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spin size="large" />
            </div>
        );
    }

    if (newsStore.error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Alert message={newsStore.error} type="error" />
            </div>
        );
    }

    return (
        <div className="bg-custom-background wrapper h-[calc(100vh-100px)]">
            <div className="container mx-auto p-4">
                <div className="flex flex-col space-y-4">
                    {newsStore.news.map((news) => (
                        <Card key={news.id} className="shadow-lg flex flex-row items-start">
                            <img
                                alt={news.title}
                                src={news.image}
                                className="w-24 h-24 object-cover mr-4"
                            />
                            <div>
                                <Meta title={news.title} description={news.createdAt}/>
                                <p className="mt-4">{news.content}</p>
                            </div>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default NewsPage;
