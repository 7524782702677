import React from "react";
import logo from "../images/rulogo1.png";
import logo_fond from "../images/fond.png";
import { Link } from "react-router-dom";
import { Button } from "antd";
import {authStore} from "../data/mobx/authStore";

const handleLogout = () => {
    authStore.logout();
};
export function NavigationBottom() {
    return (
        <nav className="relative bottom-0 w-full flex justify-between items-center h-[50px] sm:h-[100px] px-5 sm:px-10 z-20 transparent-bg">
            <a className="z-10 max-w-28 sm:max-w-xl transparent-bg" href="/">
                <img src={logo} width={150} height={125} alt="Logo" className="transparent-bg" />
            </a>
            <div className="z-10 font-light font-comfortaa text-xs transparent-bg ml-6">При поддержке:</div>
            <a className="z-10 max-w-28 sm:max-w-xl transparent-bg ml-4" href="https://fasie.ru/">
                <img src={logo_fond} width={80} height={80} alt="Logo" className="transparent-bg" />
            </a>
            <div className="z-10 font-light font-comfortaa text-[10px] text-center transparent-bg ml-4 mr-4 h-10 max-w-[500px]">Проект создан при поддержке Федерального государственного бюджетного учреждения "Фонд содействия развитию малых форм предприятий в
                научно-технической сфере в рамках программы "Студенческий стартап" федерального проекта "Платформа университетского технологического предпринимательства</div>
            <div className="flex-1"></div>
            <div className="z-10 flex space-x-5 transparent-bg items-center">
                <Link to="/news" className="font-light font-comfortaa text-xs sm:text-xs hover:text-raspberry z-50 transparent-bg flex items-center">
                    Новости
                </Link>
                <Link to="/choose" className="font-light font-comfortaa text-xs sm:text-xs hover:text-raspberry z-50 transparent-bg flex items-center">
                    Курс
                </Link>
                <Link to="/about" className="font-light font-comfortaa text-xs sm:text-xs hover:text-raspberry z-50 transparent-bg flex items-center">
                    О Проекте
                </Link>
                <Link to="/contacts" className="font-light font-comfortaa text-xs sm:text-xs hover:text-raspberry z-50 transparent-bg flex items-center">
                    Контакты
                </Link>
                {authStore.isAuthenticated ? (<Link className="z-10 flex items-center" to="/login">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mb-3 font-comfortaa text-xs items-center"
                        style={{background: '#C61437', width: '80px', height: '30px', marginBottom: '0px'}}
                        onClick={handleLogout}
                    >
                        <div style={{backgroundColor: '#C61437'}}>Выйти</div>
                    </Button>
                </Link>) : (<Link className="z-10 flex items-center" to="/login">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mb-3 font-comfortaa text-sm items-center"
                        style={{background: '#C61437', width: '80px', height: '30px', marginBottom: '0px'}}
                    >
                        <div style={{backgroundColor: '#C61437'}}>Войти</div>
                    </Button>
                </Link>)}
            </div>
        </nav>
    );
}
